import { userSignUpConstants } from 'redux/constant';

const initialState = {
  signingUp: false,
  user: null,
  error: null,
  message: '',
  permissions: [],
};

export const userSignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case userSignUpConstants.USER_SIGNUP_REQUEST:
      return {
        ...state,
        signingUp: true,
        error: null,
      };
    case userSignUpConstants.USER_SIGNUP_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { roles } = action.payload;
      // Flatten permissions from all roles
      // eslint-disable-next-line no-case-declarations
      const permissions = roles.reduce((acc, role) => {
        return [...acc, ...role.permissions];
      }, []);
      return {
        ...state,
        signingUp: false,
        user: {
          id: action.payload.userId,
          name: action.payload.username,
          spaceId: action.payload.spaceId,
          environmentId: action.payload.environmentId,
          roles: action.payload.roles,
          permissions,
        },
        message: action.message,
      };
    case userSignUpConstants.USER_SIGNUP_FAILURE:
      return {
        ...state,
        signingUp: false,
        error: action.message,
      };
    default:
      return state;
  }
};
