/* eslint-disable no-case-declarations */
import { AuthConstant } from '../constant';

const initialState = {
  payload: null,
  roles: [],
  permissions: [],
  type: '',
  error: '',
  methodParameters: {},
  count_403: 0,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AuthConstant.LOGINSUCCESS:
      const { roles = [] } = action.payload || {};

      // Flatten permissions from all roles
      const permissions = roles.reduce((acc, role) => {
        if (role.permissions && Array.isArray(role.permissions)) {
          return [...acc, ...role.permissions];
        }
        return acc;
      }, []);

      return {
        ...state,
        payload: action.payload,
        roles,
        permissions, // Save flattened permissions array
        type: action.type,
        error: '',
      };

    case AuthConstant.LOGINFAIL:
      return {
        ...state,
        payload: action.payload,
        roles: [],
        permissions: [],
        type: action.type,
        error: action.error || 'Login failed',
      };

    case AuthConstant.REGISTERSUCCESS:
      const registerRoles = action.payload.roles || [];
      const registerPermissions = registerRoles.reduce((acc, role) => {
        if (role.permissions && Array.isArray(role.permissions)) {
          return [...acc, ...role.permissions];
        }
        return acc;
      }, []);

      return {
        ...state,
        payload: action.payload,
        roles: registerRoles,
        permissions: registerPermissions,
        type: action.type,
        error: '',
      };

    case AuthConstant.REGISTERFAIL:
      return {
        ...state,
        payload: action.payload,
        roles: [],
        permissions: [],
        type: action.type,
        error: action.error || 'Registration failed',
      };

    case AuthConstant.VERIFYSUCCESS:
      // eslint-disable-next-line no-case-declarations
      const verifyRoles = action.payload.roles || [];
      // eslint-disable-next-line no-case-declarations
      const verifyPermissions = verifyRoles.reduce((acc, role) => {
        if (role.permissions && Array.isArray(role.permissions)) {
          return [...acc, ...role.permissions];
        }
        return acc;
      }, []);

      return {
        ...state,
        payload: action.payload,
        roles: verifyRoles,
        permissions: verifyPermissions,
        type: action.type,
        error: '',
      };

    case AuthConstant.VERIFYFAIL:
      return {
        ...state,
        payload: action.payload,
        roles: [],
        permissions: [],
        type: action.type,
        error: action.error || 'Verification failed',
      };

    case AuthConstant.INVALID_TOKEN:
      return {
        ...state,
        type: action.type,
        methodParameters: action.methodParameters,
        error: action.message || 'Invalid token',
      };

    case AuthConstant.SET_403_ERROR_COUNT:
      return {
        ...state,
        count_403: state.count_403 + 1,
      };

    case AuthConstant.SET_403_ERROR_COUNT_0:
      return {
        ...state,
        count_403: 0,
      };

    default:
      return state;
  }
}
