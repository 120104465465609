import { roleConstants } from '../constant/rolesAndPermissionConstant';

const initialState = {
  payload: null,
  message: '',
  loading: false,
};

export const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleConstants.ROLE_SAVE_REQUEST:
    case roleConstants.ROLE_UPDATE_REQUEST:
    case roleConstants.ROLE_GETALL_REQUEST:
    case roleConstants.ROLE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case roleConstants.ROLE_SAVE_SUCCESS:
    case roleConstants.ROLE_GETALL_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        message: action.message,
        loading: false,
      };

    case roleConstants.ROLE_UPDATE_SUCCESS:
      return {
        ...state,
        payload: state.payload.map((role) =>
          action.payload.some((updatedRole) => updatedRole.id === role.id)
            ? action.payload.find((updatedRole) => updatedRole.id === role.id)
            : role
        ),
        message: action.message,
        loading: false,
      };

    case roleConstants.ROLE_DELETE_SUCCESS:
      return {
        ...state,
        payload: state.payload.filter((role) => role.id !== action.payload.id),
        message: action.message,
        loading: false,
      };

    case roleConstants.ROLE_SAVE_FAILURE:
    case roleConstants.ROLE_UPDATE_FAILURE:
    case roleConstants.ROLE_GETALL_FAILURE:
    case roleConstants.ROLE_DELETE_FAILURE:
      return {
        ...state,
        message: action.message,
        loading: false,
      };

    default:
      return state;
  }
};
