export const getAllCampaignConstant = {
  FETCH_CAMPAIGNS_REQUEST: 'FETCH_CAMPAIGNS_REQUEST',
  FETCH_CAMPAIGNS_SUCCESS: 'FETCH_CAMPAIGNS_SUCCESS',
  FETCH_CAMPAIGNS_FAILURE: 'FETCH_CAMPAIGNS_FAILURE',
  FETCH_AB_TESTS_REQUEST: 'FETCH_AB_TESTS_REQUEST',
  FETCH_AB_TESTS_SUCCESS: 'FETCH_AB_TESTS_SUCCESS',
  FETCH_AB_TESTS_FAILURE: 'FETCH_AB_TESTS_FAILURE',
  FETCH_PERSONALIZATIONS_REQUEST: 'FETCH_PERSONALIZATIONS_REQUEST',
  FETCH_PERSONALIZATIONS_SUCCESS: 'FETCH_PERSONALIZATIONS_SUCCESS',
  FETCH_PERSONALIZATIONS_FAILURE: 'FETCH_PERSONALIZATIONS_FAILURE',
  CLEAR_DATA: 'CLEAR_DATA',
  SET_LOADING_FALSE: 'SET_LOADING_FALSE',
  SET_LOADING_TRUE: 'SET_LOADING_TRUE',
  CLEAR_TYPE: 'CLEAR_TYPE',
  CLEAR_ALL_DATA: 'CLEAR_ALL_DATA',
};
