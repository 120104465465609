import { userRoleConstants } from '../constant/userRoleConstant';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const userRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case userRoleConstants.USER_ROLE_ADD_REQUEST:
    case userRoleConstants.USER_ROLE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case userRoleConstants.USER_ROLE_ADD_SUCCESS:
    case userRoleConstants.USER_ROLE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case userRoleConstants.USER_ROLE_ADD_FAILURE:
    case userRoleConstants.USER_ROLE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      };

    default:
      return state;
  }
};
