export const userConstants = {
  USER_GETALL_REQUEST: 'USER_GETALL_REQUEST',
  USER_GETALL_SUCCESS: 'USER_GETALL_SUCCESS',
  USER_GETALL_FAILURE: 'USER_GETALL_FAILURE',

  USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',

  DEFAULTERR: 'An error occurred while fetching data',
};
